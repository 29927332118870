import Routing from './Routing'
import './componentes/App.css';
import React from 'react';


function App() {
  return (
    <div className="App" style={{minHeight:"100vh"}}>
      <Routing/>
    </div>
  );
}

export default App;
