import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import './Footer.css';

function Footer() {
    return (
        <>
            <div className='footer'>
                <Container className='pt-5 pb-3'>
                    <p style={{color:"white", textAlign:'right', textShadow: 'black 1px 0 10px'}}>Fotografia de Soraia Silva<br/>Ilustração de <a style={{color:"white", textAlign:'right'}} href='https://www.instagram.com/bichosdapoly?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='>Catarina Hipólito</a><br/>contacto: CamposUmPasso@gmail.com</p>
                </Container>
            </div>
        </>
    );
}


export default Footer;