import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";

//livros imagens
import livro1 from '../Photos/livro1.jpg'
import livro2 from '../Photos/livro2.jpg'
import livro3 from '../Photos/livro3.jpg'

export default function LojaOnline() {
    return (
        <Container className="pt-3 pb-3">
            <h1 className="pb-3">Loja Online</h1>
            <Card style={
                { textAlign: 'left' }
            } className="mb-3">
                <Row className="p-3" sm={'2'} xs={'1'} fluid={'auto'}>
                    <Col md={3}>
                        <Image src={livro1} width={'100%'}></Image>
                    </Col>
                    <Col md={9} >
                        <Card.Title>Espirros de Boca Fechada e os Olhos Tristes</Card.Title>
                        <Card.Subtitle>Edição: 31</Card.Subtitle>
                        <Card.Text>Livro dedicado à doença... É um homem a espirrar de bocado fechada... E tá triste.... tenho de explicar o quê?</Card.Text>
                        <div className="d-grid ">
                        <Button disabled>Comprar</Button>
                        </div>
                        <p style={{ color: 'red', fontStyle: 'italic' }}>*Esgotado</p>
                    </Col>
                </Row>
            </Card>
            <Card style={
                { textAlign: 'left' }
            } className="mb-3">
                <Row className="p-3" sm={'2'} xs={'1'} fluid={'auto'}>
                    <Col md={3}>
                        <Image src={livro2} width={'100%'}></Image>
                    </Col>
                    <Col md={9} >
                        <Card.Title>Acabou-se a Tin</Card.Title>
                        <Card.Subtitle>Edição: 78</Card.Subtitle>
                        <Card.Text>Reflexão poética sobre a solidão, e o combate à mesma. Laçorda acende um fogo na alma do leitor. Nesta obra Laçorda espera despertar um movimento filosófico e ativista baseado em ações de proximidade de corpo, almas e fluidos.</Card.Text>
                        <div className="d-grid ">
                        <Button disabled>Comprar</Button></div>
                        <p style={{ color: 'red', fontStyle: 'italic' }}>*Esgotado</p>
                    </Col>
                </Row>
            </Card>
            <Card style={
                { textAlign: 'left' }
            } className="mb-3">
                <Row className="p-3" sm={'2'} xs={'1'} fluid={'auto'}>
                    <Col md={3}>
                        <Image src={livro3} width={'100%'}></Image>
                    </Col>
                    <Col md={9} >
                        <Card.Title>No Mar e no Barco de Madeira o Homem faz a Fogueira</Card.Title>
                        <Card.Subtitle>Edição: 69</Card.Subtitle>
                        <Card.Text>O livro que quase acabou a carreira de Laçorda Campos está disponível para todos lerem. Obra poética revelativa do estado de espírito do poeta quando a sua carreira esteve para acabar.</Card.Text>
                        <div className="d-grid ">
                            <Button href="https://www.youtube.com/watch?v=xvFZjo5PgG0">Comprar</Button>
                        </div>
                        <p style={{ color: 'red', fontStyle: 'italic' }}>*2 unidades em stock</p>
                    </Col>
                </Row>
            </Card>
        </Container>
    )
}