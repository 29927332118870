import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Photos from "./Photos";

export default function OPoeta() {
    return (
        <Container className="pb-4">
            <h1 className="pt-3 pb-3">O Poeta</h1>
            <Row className="pb-3" lg={'2'} xs={'1'}>
                <Col>
                    <p>
                        Que dizer sobre um homem que já tem os feitos marcados nas páginas da história?
                        Laçorda Campos nasceu em 1995 é portanto ainda
                        considerado um jovem. Aos 2 anos de idade já se destacava no primeiro ano de escolaridade com o famoso verso 
                        "estas são as linhas por onde se escreve a poesia".
                        Lançou o seu primeiro livro de poemas aos 5 anos intitulado, "Para fazer a Joana pensar... em namorar comigo". 
                        Desse livro resulta ainda, uma
                        nostalgia marcada pelos poemas "Sou! O que corre mais rápido da turma" e "As cores de um cubo Mágico".
                        Em anos sucessivos de 2016, 2017 e 2018 foi premiado com o prémio Sofia sem nunca ter ido ao cinema.
                        No ano seguinte de 2019 mudou-se para o Dubai onde desde então tem privado com o papá Francisco a quem faz ativamente o acompanhamento espiritual.
                        Em 2020, aquando da época COVID 19, o seu poema "Espirros de Bocado Fechada e Olhos Tristes" tornou-se obra essencial para compreender o vírus e
                        criar a vacina da Johnson.
                    </p>
                    <p>
                    - The New York Times 2022 -
                    </p>
                </Col>
                <Col>
                    <h4 style={{ textAlign: 'left' }}>A Palavra</h4>
                    <Card style={{ width: '100%', textAlign: 'left' }} className="mt-3">
                        <Card.Body>
                            <Card.Title>"Não é o homem que se faz à poesia... mas sim a poesia que se faz ao homem"</Card.Title>
                        </Card.Body>
                        <Card.Footer>Abril 2015</Card.Footer>
                    </Card>
                    <Card style={{ width: '100%', textAlign: 'left' }} className="mt-3">
                        <Card.Body>
                            <Card.Title>"Uma pessoa que de Barco ande, só pesca se houver a fome"</Card.Title>
                        </Card.Body>
                        <Card.Footer>Março 2010</Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Photos></Photos>

        </Container>
    )
}