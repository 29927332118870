import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';

import logo from '../logo.svg'

/**
 * Funciona como uma nav bar colada ao topo
 * da web app, quando é feito o resize para telemovel
 * fica com um menu de opções.
 * 
 * Ao clicar em qualquer um dos links é redirecionado
 * para o local da pagina onde esta uma div com esse id.
 */
function Header() {
  return (
    <>
      <Navbar collapseOnSelect expand='sm' variant="light" >
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Home
          </Navbar.Brand>
          <Navbar.Toggle aria-aria-controls='responsive-nav-bar' />
          <Navbar.Collapse id='responsive-nav-bar'>
            <Nav className="me-auto">

              <Nav.Link href="Interpretes">Interpretes</Nav.Link>
              <Nav.Link href="/ContactoProducao">Contacto de Produção</Nav.Link>
              <Nav.Link href="/Galeria">Galeria</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default Header;