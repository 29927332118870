import { Card, Col, Container, Image, Row, Table } from "react-bootstrap";
import React from 'react';
import './App.css'
import quadros from '../Photos/Quadros.jpg'
import Datas from "./Datas";
import OPoeta from "./OPoeta";
import LojaOnline from './LojaOnline'


export default function MainPage() {

    return (
        <>
            <Container alt="Max-width 100%">
                <h1 className="pb-3">Laçorda Campos</h1>
                <div class="ratio ratio-16x9">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/yMgOhoFJ1jI?si=fI_sFGuQ8UyWMtV4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </Container>
            {/** 
            <h1 className="pb-3">Datas</h1>
            {Datas()}
*/}
            <Container alt="Max-width 100%" >
                <Row className="pt-3 pb-3" lg={'2'} xs={'1'}>
                    <Col>
                        <h2>Um Passo pela Poesia</h2>
                        <h3>Sinopse</h3>
                        <p>
                            Em 2024 Laçorda sai do seu Retiro espiritual novamente para
                            o olhar público. Desta vez "nos seus termos" para que "as pessoas me conheçam no intimo".
                            Um passo pela poesia, é uma noite dedicada à palavra de Laçorda campos em convívio com os seus leitores.
                            É um espectáculo para uma faixa etária superior aos 16
                            anos que contém linguagem provocadora capaz de ferir susceptibilidades de algumas pessoas.
                            Apesar disso é um Espectáculo de entretenimento que ousa ter os seus momentos sérios e pensativos,
                            ilustrado com imagens e acompanhado do seu guitarrista de eleição Frodo.
                            Laçorda vem ter com vocês a vossa casa para passar a sua palavra.
                        </p>
                        <p>
                            Encenação de Miguel Henriques música de André Almeida.
                        </p>
                    </Col>
                    <Col>
                        <Image src={quadros} width={'70%'} className="p-4"></Image>
                    </Col>
                </Row>
            </Container>



            <Container alt="Max-width 80%" className="pb-3">
                {/*
                */}

                <OPoeta></OPoeta>
                <LojaOnline></LojaOnline>
            </Container>
        </>);
}