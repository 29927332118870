import { IconButton } from "@mui/material";
import React from "react"
import { Container } from "react-bootstrap"
import { FaYoutube } from "react-icons/fa"
import './Footer.css'


export default function ContactoProd() {
    return (
        <Container className="content">
            <h1 className="pt-3">Contacto de Produção</h1>
            <p className="pt-3" style={{ textAlign: 'center' }}>Contacto de Produção/Agendamento: CamposUmPasso@gmail.com</p>
            <p className="pt-3" style={{ textAlign: 'center' }}>Miguel Henriques</p>

            <IconButton color="#8d3a3e" href="https://www.youtube.com/@Umpassopelapoesia">
                <FaYoutube color="#8d3a3e" />
            </IconButton>

            <p className="pt-3" style={{ textAlign: 'center' }}>Fotografia de Soraia Silva<br />Ilustração de <a style={{ color: "black" }} href='https://www.instagram.com/bichosdapoly?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='>Catarina Hipólito</a></p>

        </Container>
    )
}