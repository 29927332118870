import React from "react"
import { Card, Col, Container, Image, Row } from "react-bootstrap"
import miguel from '../Photos/Miguel.jpg'
import andre from '../Photos/Andre.jpg'

export default function Interpretes() {
    return (
        <>
            <Container className="pt-3 pb-3">
                <h1 className="pb-3">Interpretes</h1>
                <Card style={
                    { textAlign: 'left' }
                } className="mb-3">
                    <Row className="p-3" sm={'2'} xs={'1'} fluid={'auto'}>
                        <Col md={3}>
                            <Image src={miguel} width={'100%'}></Image>
                        </Col>
                        <Col md={9} >
                            <Card.Title>Miguel Henrriques</Card.Title>
                            <Card.Text>
                                Formado em teatro pelo conservatório art'j, começou a fazer teatro aos catorze anos através da associação albergart-te, "oficina de teatro jovem". Participou vários anos consecutivos com a mesma "oficina de teatro jovem" no projecto Panos.
                                Prosseguiu estudos no politécnico do porto ESMAE durante um ano, tendo decidido não concluir esse ano e desde então tem viajado pela Europa através do programa Erasmus +, em projectos associados com teatro, inteligência emocional e trabalho com a comunidade.
                                Como intérprete tem como característica ser polivalente nas suas competências sendo capaz de utilizar diferentes médiuns nas diversas áreas artísticas do circo, música, etc.
                            </Card.Text>
                        </Col>
                    </Row>
                </Card>
                <Card style={
                    { textAlign: 'left' }
                } className="mb-3">
                    <Row className="p-3" sm={'2'} xs={'1'} fluid={'auto'}>
                        <Col md={3}>
                            <Image src={andre} width={'100%'}></Image>
                        </Col>
                        <Col md={9} >
                            <Card.Title>André Almeida</Card.Title>
                            <Card.Text>
                                Jovem dinâmico, estudante de engenharia informática com um interesse profundo nas artes performativas.
                                Músico semiprofissional começou a estudar guitarra aos 12 anos. Em 2020 começou o conjunto musical de rock com letras em português “Cerial Quiller”. Em 2022 decidiu criar um festival de rock na vila de Avanca de nome "Avanca Gare".
                                Contou com uma participação regular nos episódios da websérie "Raccord" do grupo de humor 5ª geração, onde conheceu Miguel Henriques, e lhe foi sugerida a criação do presente espectáculo.
                            </Card.Text>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}