import Header from './componentes/Header';
import Footer from './componentes/Footer';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Cartaz from './componentes/Cartaz';
import React from 'react';
import MainPage from './componentes/MainPage';
import Interpretes from './componentes/Interpretes';
import ContactoProd from './componentes/ContactoProd';
import Galeria from './componentes/Galeria';

// Fotos
import p1 from './Photos/galeria/1.jpg'
import p2 from './Photos/galeria/2.jpg'
import p3 from './Photos/galeria/3.jpg'
import p4 from './Photos/galeria/4.jpg'
import p5 from './Photos/galeria/5.jpg'
import p6 from './Photos/galeria/6.jpg'
import p7 from './Photos/galeria/7.jpg'
import p8 from './Photos/galeria/8.jpg'
import p9 from './Photos/galeria/9.jpg'
import p10 from './Photos/galeria/10.jpg'
import p11 from './Photos/galeria/11.jpg'
import p12 from './Photos/galeria/12.jpg'
import p13 from './Photos/galeria/13.jpg'
import p14 from './Photos/galeria/14.jpg'
import p15 from './Photos/galeria/15.jpg'
import p16 from './Photos/galeria/16.jpg'
import p17 from './Photos/galeria/17.jpg'

/**
 * Test
 * @returns MainApp
 */
export default function Routing() {

    const photosGalery = [
        p1,p2,p4,p3,p5,
        p7,
        p8,
        p6,
        //p9,
        p10,
        p11,
        p13,
        p14,
        p15,
        p16,
        p17
    ]

    return (
        <div className="main" style={{ minHeight: "100vh" }}>
            <BrowserRouter>
                <Routes>
                    {/* Common routes with Header and Footer */}
                    <Route
                        path="/"
                        element={
                            <>
                                <Header />
                                <Cartaz />
                                {/* Outlet for nested routes */}
                                <Outlet />
                                <Footer />
                            </>
                        }
                    >
                        <Route path="/" element={<MainPage />} />
                        <Route path="/Interpretes" element={<Interpretes />} />
                        <Route path="/Galeria" element={Galeria(photosGalery)} />
                    </Route>
                    <Route
                        path="/"
                        element={
                            <>
                                <Header />
                                <Cartaz />
                                {/* Outlet for nested routes */}
                                <Outlet />

                            </>
                        }
                    >
                        <Route path="/ContactoProducao" element={<ContactoProd />} />

                    </Route>

                    {/* Special route without Header and Footer */}
                </Routes>
            </BrowserRouter>
            {//AlertCopied(alert)
            }
        </div>
    );
}