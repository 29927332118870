import * as React from 'react';
import { Dialog, DialogContent, ImageList, ImageListItem } from '@mui/material';
import { useState } from 'react';


/**
 * 
 * @returns Galeria de Fotografias
 */
export default function Galeria(itemData) {

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    return (
        <div className='main'>
            <ImageList sx={{
                paddingTop: "2%"
            }} cols={3}>
                {itemData.map((item) => (
                    <ImageListItem key={item}>
                        <img
                            src={item}
                            loading="lazy"
                            onClick={() => handleImageClick(item)}
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Dialog open={selectedImage !== null} onClose={handleClose} >
                <DialogContent style={{
                    padding: '0px',
                    overflow: 'hidden'
                }} >
                    {selectedImage && (
                        <img src={selectedImage} style={{ width: '100%' }} />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}

