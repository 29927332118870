import './Cartaz.css'
import React from 'react';

export default function Cartaz() {

    return (
        <div id="cartaz">
           
        </div>
    );
}